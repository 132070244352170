
// Bootstrap points

@media screen and (max-width: 1199px) {
  .news-card {
    &__body {
      height: 22vw;
    }
  }

  .alerts-card {
    &__body {
      height: 12vw;
    }
  }
}

@media screen and (max-width: 991px) {
  .news-card {
    &__body {
      height: 30vw;
    }
  }

  .alerts-card {
    &__body {
      height: 15vw;
    }
  }
}

@media screen and (max-width: 767px) {
  .news-card {
    &__body {
      height: 55vw;
    }
  }

  .alerts-card {
    &__body {
      height: 30vw;
    }
  }
}

@media screen and (min-width: 576px) {
  .modal-dialog {
    &.size-lg {
      max-width: 70%;
    }
    &.size-xl {
      max-width: 90%;
    }
  }
}

@media screen and (max-width: 575px) {

}
