.nav-link {
  &--active {
    font-weight: bold;
  }
}

.sidebar .nav .nav-title {
  text-transform: initial;
}

.button-group {
  & button {
    margin-right: 1rem;
  }
}

.button-row {
  margin-bottom: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.yorc-logo {
  &__footer {
    width: auto;
    height: 30px;
    margin-left: 10px;
  }
}

.main-card-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    & > i {
      margin-right: 0.75rem;
    }
  }
}

.redirect-card {
  text-align: center;
  cursor: pointer;
  &__header {
    font-size: 1.2rem;
    font-weight: bold;
  }
  &__body {
    font-size: 10rem;
    color: $color-secondary;
    opacity: 0.7;
  }
  &:hover {
    .redirect-card__body {
      opacity: 1;
    }
  }
}

.success-modal {
  & .modal-header {
    color: white;
    background: $color-success;
  }
}

.primary-modal {
  & .modal-header {
    color: white;
    background: $color-primary;
  }
}

.alert-modal {
  & .modal-header {
    color: white;
    background: $color-danger;
  }
}

.warning-modal {
  & .modal-header {
    color: white;
    background: $color-warning;
  }
}

.spoiler {
  color: $color-dark;
  font-size: 0.75rem;
  font-style: italic;
}


// Redefine default styles

header.app-header {
  & .navbar-toggler {
    outline: none;
  }
}

.sidebar .nav-item {
  font-size: 0.8rem;
}

.main {
  &__inner-page {
    margin-top: $breadcrumbs-height;
  }
}

.breadcrumbs {
  &__wrapper {
    position: fixed;
    width: 100%;
    z-index: 1001;
  }
}

.modal-content {
  border: none;
}

.form-group {
  & label {
    font-weight: bold;
  }
}

.multi-select {
  --rmsc-border: #e4e7ea;
  --rmsc-background: #fff;
  --rmsc-spacing: 0.375rem;
  --rmsc-border-radius: 0.25rem;
  --rmsc-height: calc(1.5em + 0.75rem + 2px);
}

label.rdw-image-modal-upload-option-label {
  display: block;
  text-align: center;
  font-size: 0.75rem;
  font-style: italic;
  word-wrap: break-word;
}

.nav-tabs .nav-link {
  color: $color-secondary-active;
  &.active {
    color: $color-primary-active;
    &:hover {
      color: $color-primary-active;
    }
  }
}

a:hover .link-icon {
  &--pdf {
    color: red;
  }
}

.default-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}
