// Login page

.login {
  &__page-wrapper {
    background-image: linear-gradient(#eb7d3c 0, #1eb1ed 0), linear-gradient(#eb7d3c 100%, #1eb1ed 0);
    background-size: 100% 8%, 100% 24%;
    background-repeat: no-repeat;
    background-position: 0 center;
  }
  &__form-wrapper {
    background: rgba(255, 255, 255, 0.9);
  }
}


// Home page

.home-page {
  &__wrapper {
    position: relative;
    min-height: 70vh;
    background-size: auto 80%;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.25;
  }
}


// Guides page

.category {
  &__table-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__icon-wrapper {
    margin: 0 1rem;
    & > img {
      border-radius: 0.375rem;
    }
  }
}


// Analytics page

.analytics-page {
  min-width: 320px;
  margin: 0 auto;
  padding: 0;
}
