$color-light: #666666;
$color-success: #4dbd74;
$color-success-active: #3a9d5d;
$color-primary: #20a8d8;
$color-primary-active: #1985ac;
$color-secondary: #c8ced3;
$color-secondary-active: #acb5bc;
$color-warning: #ffc107;
$color-warning-active: #d39e00;
$color-danger: #f86c6b;
$color-danger-active: #f63c3a;
$color-dark: #2f353a;
$color-dark-active: #181b1e;

$border-color: #c8ced3;


// Redefine defaults

$breadcrumbs-height: 4.375rem;
