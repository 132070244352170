.error404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  cursor: default;
  user-select: none;
  color: #ccc;
  &__title {
    font-size: 60px;
  }
  &__subtitle {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.under-construction {
  color: $color-secondary;
  text-align: center;
  & .fa {
    font-size: 18rem;
    padding: 3rem;
  }
}

.news-card {
  &__header {
    overflow: hidden;
    & > p {
      overflow: hidden;
      padding: 0;
      margin: 0;
      line-height: 1.2rem;
      height: 3.6rem;
    }
  }
  &__body {
    height: 15vw;
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
  &__date {
    font-size: 0.8rem;
    color: $color-light;
    font-style: italic;
  }
}

.news-detail {
  overflow: hidden;
  &__image-wrapper {
    max-width: 24vw;
    float: left;
    margin: 0 2rem 2rem 0;
    &--for-icon {
      max-width: 150px;
    }
    & > img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
  &__text-wrapper {
    & > img {
      max-width: 100%;
    }
  }
}

.alerts-card {
  &__header {
    overflow: hidden;
    & > p {
      overflow: hidden;
      padding: 0;
      margin: 0;
      font-size: 0.8rem;
      line-height: 1rem;
      height: 3rem;
    }
  }
  &__body {
    height: 9vw;
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
  &__date {
    font-size: 0.8rem;
    color: $color-light;
    font-style: italic;
  }
}

.alerts-detail {
  &__push-wrapper {
    position: relative;
    overflow: hidden;
    width: 400px;
    border: 1px solid $border-color;
    border-radius: 0.75rem;
    padding: 0.75rem;
    margin: 0 auto 2rem;
  }
  &__push-image {
    float: left;
    width: 44px;
    height: 44px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 16px;
  }
  &__push-title {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 22px;
    width: 260px;
    max-height: 22px;
    margin-top: 2px;
  }
  &__push-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 22px;
    max-height: 22px;
  }
  &__push-date {
    color: $color-dark;
    font-size: 0.75rem;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  &__image-wrapper {
    max-width: 400px;
    margin: 2rem auto;
    & > img {
      width: 100%;
      height: auto;
    }
  }
}

.actions-group {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  &__item {
    padding: 0.375rem;
    cursor: pointer;
    user-select: none;
  }
  &__table-cell {
    justify-content: center;
  }
}

.group-type {
  &__icon {
    font-size: 1.5rem;
    color: $color-danger;
    margin-left: 1rem;
    &.fa-lock {
      color: $color-primary;
    }
  }
}

.image-preview {
  height: 10rem;
  position: relative;
  &__wrapper {
    position: absolute;
    width: 20rem;
    height: 12rem;
    top: 0;
    right: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.wysiwyg {
  &__editor {
    max-height: 60vh;
    min-height: 25vh;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
  }
}

.statistic-table {
  &__title__mobile {
    margin-top: 30px;
  }
  &__cell__mobile {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid $border-color;
    border-radius: 0.25rem;
  }
  &__item__mobile {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dotted $border-color;
    &:last-child {
      border: none;
    }
    & > p {
      margin: 0;
      padding: 0;
      max-width: 80%;
    }
  }
}

.chart-column {
  overflow-y: hidden;
}

.body-header {
  margin-bottom: 20px;
  &--flex-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
